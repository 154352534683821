@font-face {
  font-family: "iconfont"; /* Project id 3474822 */
  src: url('iconfont.woff2?t=1655697340623') format('woff2'),
       url('iconfont.woff?t=1655697340623') format('woff'),
       url('iconfont.ttf?t=1655697340623') format('truetype'),
       url('iconfont.svg?t=1655697340623#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fireworkbiyanjing:before {
  content: "\e901";
}

.fireworkyanjing:before {
  content: "\e8c7";
}

