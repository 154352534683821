.LatestNews {
    position: relative;
    height: 100vh;

    color: #fff;
    background-color: #000;
    font-family: ShentoxMedium;

    .content {
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 70vw;
        margin: 0 auto;
        line-height: 1.5;
        max-height: 80vh;
        overflow-y: scroll;

        h2 {
            font-size: 40px;
            margin-bottom: 30px;
        }

        .TitleDate {
            display: inline-block;
            margin-bottom: 30px;
            font-size: 12px;
        }

        .pagination {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70%;
            margin: 120px auto;
            .prePage {
                background: url('../../assets/imgs/prePage.png') 4px 3px no-repeat;
                background-size: 10%;
                padding-left: 20px;
                cursor: pointer;
            }

            .nextPage {
                background: url('../../assets/imgs/nextPage.png') 75px 3px no-repeat;
                background-size: 12%;
                padding-right: 20px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .LatestNews {
        .content {
            h2 {
                font-size: 20px;
            }
        }
    }
}
