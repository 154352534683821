.guide {
    color: #ffffff;
    width: 100vw;
    padding-top: 30vh;
    min-width: 1200px;
    min-height: 100vh;
    background: #000;
    font-family: ShentoxMedium;
    header {
        width: 80%;
        margin: 0 auto;
        h1 {
            font-size: 38px;
        }
        > div {
            display: flex;
            font-size: 16px;
            .headerLeft {
                line-height: 40px;
            }
            .headerRight {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;
                .btnGroup,
                p {
                    display: inline-block;
                }
                .btnGroup {
                    margin-bottom: 10px;
                    display: flex;
                    > div {
                        margin-right: 15px;
                        width: 100px;
                        text-align: center;
                        background: #2d2d2d;
                    }
                }
                p {
                    font-size: 14px;
                    width: 362px;
                }
            }
        }
    }
    article {
        width: 80%;
        margin: 0 auto;
    }
}
