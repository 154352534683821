@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: auto;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% auto;
    position: relative;
}

.header {
    height: 100px;
    // background-color: orange;
    width: 100%;
    max-width: 1200px;
    margin: 160px auto 0;
    display: flex;
    align-items: stretch;
    .poolInfos {
        display: flex;
        flex-direction: column;
        .poolName {
            display: flex;
            align-items: center;
            font-size: 40px;
            font-weight: bold;
            color: #b4c0db;
        }
        .poolTime {
            font-size: 14px;
            color: #84a1ff;
        }
    }
    .divider {
        height: 60%;
        width: 1px;
        background-color: rgba(136, 246, 255, 1);
        margin: auto 20px;
    }
    .phase {
        width: 192px;
        height: 47px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 22px;
        font-family: ShentoxMedium;
        font-weight: bold;
        color: #88f6ff;
        text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.68);
        font-style: italic;
        padding-left: 46px;
        padding-top: 8px;
        // transform: translateX(-36px);
    }
    .totalInfos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        transform: translateY(-10px);
        .label {
            font-size: 16px;
            color: #88f6ff;
        }
        .value {
            margin-top: 8px;
            font-size: 18px;
            color: #ffffff;
        }
    }
}

// 区分颜色 Epic Pool
.pooltype2 {
    .flexBox .left .poolTitle {
        color: #b986ff;
    }
    .flexBox .panel .form .blueText {
        color: #ff9c84;
    }
    .flexBox .panel .form .left .btn {
        background-color: rgba(116, 51, 184, 1);
    }
    .flexBox .panel .form .left .dashBox {
        border-color: rgba(196, 159, 255, 1);
    }
    .flexBox .panel .form .right .rewardsBox .rewardsInfos .box {
        background-color: rgba(177, 165, 100, 0.3);
    }
    .flexBox .panel .tips-Wj1k .colorText {
        color: #ff9c84;
    }
    .header .poolInfos .poolName {
        color: #a66bf8;
    }
    .flexBox .left .nftName {
        color: #a66bf8;
    }

    .modalWrap .modalBody {
        background-color: RGBA(35, 11, 60, 1);
        .title {
            color: #ff9c84;
        }
        .modalDecoration {
            background-color: RGBA(35, 11, 60, 1);
        }
        .btn {
            background-color: rgba(116, 51, 184, 1);
        }
    }
}
// 区分颜色 Lengendary Pool
.pooltype3 {
    .flexBox .left .poolTitle {
        color: #ffe202;
    }
    .flexBox .panel .form .blueText {
        color: #84fed9;
    }
    .flexBox .panel .form .left .btn {
        background-color: rgba(215, 174, 32, 1);
    }
    .flexBox .panel .form .left .dashBox {
        border-color: rgba(255, 244, 159, 1);
    }
    .flexBox .panel .tips-Wj1k .colorText {
        color: #84fed9;
    }
    .header .poolInfos .poolName {
        color: #ffe202;
    }
    .flexBox .left .nftName {
        color: #ffe202;
    }
    .modalWrap .modalBody {
        background-color: rgba(134, 114, 54, 1);
        .title {
            color: #84fed9;
        }
        .modalDecoration {
            background-color: gba(134, 114, 54, 1);
        }
        .btn {
            background-color: rgba(215, 174, 32, 1);
        }
    }
}
.flexBox {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto 100px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .tipsIcon {
        margin: 0 8px;
        width: 22px;
        height: 22px;
        object-fit: contain;
    }
    .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .poolTitle {
            height: 60px;
            font-size: 40px;
            font-weight: bold;
            color: #b4c0db;
        }
        .nftImage {
            width: 100%;
            height: auto;
            max-height: 320px;
            object-fit: contain;
        }
        .nftName {
            padding: 20px;
            padding-bottom: 0;
            color: #84a1ff;
        }
        .nftDesc {
            padding: 20px;
            padding-top: 12px;
            color: #ffffff;
        }
    }
    .panel {
        // width: 1419px;
        // height: 815px;
        width: 886px;
        height: 509px;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .colBox {
            display: flex;
            justify-content: space-between;
            > div {
                // flex: 1;
            }

            .divider {
                height: 80%;
                width: 1px;
                background-color: rgba(58, 68, 101, 1);
                margin: auto 20px;
            }
        }

        .left {
            flex: 1;
            padding-top: 50px;
            padding-left: 50px;
            padding-right: 50px;
            .blueText {
                margin-top: 30px;
                margin-bottom: 6px;
                font-size: 18px;
                color: #84a1ff;
                .subText {
                    font-size: 12px;
                    margin-left: 12px;
                }
            }
            .valueText {
                height: 50px;
                display: flex;
                font-size: 36px;
                font-weight: 400;
                color: #ffffff;
            }
            .textLabel {
                font-size: 20px;
                // font-weight: bold;
                color: #84a1ff;
                margin-top: 60px;
                margin-left: 50px;
            }
            .dashbox {
                flex: 1;
                // margin-top: 40px;
                // margin-left: 50px;
                margin-right: 50px;
                border: 2px dashed rgba(159, 192, 255, 0.75);
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: stretch;
                .blueText {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    font-size: 32px;
                    font-weight: bold;
                }
            }

            .btnBox {
                // margin-top: 40px;
                // margin-left: 50px;
                width: 240px;
                height: 50px;
            }

            .btn {
                width: 240px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                background-color: rgba(75, 90, 172, 1);
                cursor: pointer;
                user-select: none;
                &.disabled {
                    cursor: not-allowed;
                    color: #969696;
                    background-color: rgba(95, 95, 95, 0.8);
                }
            }

            .tips {
                // margin: 40px 50px;
                margin-top: 60px;
                // margin-left: 50px;
                // margin-right: 50px;
                // margin-top: 20px;
                line-height: 1.5;
                font-size: 12px;
                color: #ddd;
                .colorText {
                    // font-size: 16px;
                    color: #84a1ff;
                }
                ul {
                    li {
                        margin-left: 16px;
                    }
                }
            }
        }

        .right {
            width: 30%;
            .rewardsImage {
                margin-top: 40px;
                margin-right: 50px;
                max-width: 150px;
                flex: 1;
                // width: 276px;
                // height: 312px;
                // width: 230px;
                // height: 260px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .rewardsInfos {
                margin-right: 50px;
                // width: 60%;
                color: RGBA(220, 221, 224, 1);
                .label {
                    padding: 20px 20px 12px;
                    // height: 30px;
                }
                .box {
                    // border: 2px solid rgba(87, 105, 132, 0.66);
                    background-color: rgba(100, 107, 142, 0.3);
                    height: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    // padding-left: 20px;
                    // padding-right: 20px;
                    padding: 4px 20px;
                    font-size: 14px;
                }
            }
        }
    }
}

.modalWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    // backdrop-filter: blur(0);
    // backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease;
    .modalBody {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
        // 1121px x 832px
        width: 560px;
        height: 416px;
        padding: 20px;
        // background: orange;
        // background: #1f222e;
        // box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: relative;
        .modalDecoration {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 300px;
            height: 10px;
            background: #2b3041;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
        }
        .title {
            margin: 20px;
            font-size: 30px;
            height: 50px;
            color: #b4c0db;
            font-weight: bold;
        }
        .message {
            flex: 1;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 120px;
                height: 120px;
                object-fit: contain;
            }
            .gourp {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: stretch;
                .left,
                .right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                }
                .label {
                    font-size: 18px;
                    color: #84a1ff;
                    display: flex;
                    justify-content: center;
                }
                .text {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 30px;
                }
                .divider {
                    height: 60%;
                    width: 1px;
                    background-color: rgba(112, 112, 112, 0.6);
                    margin: auto 12px;
                }
            }

            .tips {
                line-height: 1.5;
                font-size: 12px;
                color: #ddd;
                .colorText {
                    color: #84a1ff;
                }
                ul {
                    li {
                        margin-left: 16px;
                    }
                }
            }
        }
        .btn {
            margin: 30px;
            width: 300px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            background-color: rgba(75, 90, 172, 1);
            opacity: 0.8;
            cursor: pointer;
            user-select: none;
            transition: opacity 150ms ease;
            &:hover {
                opacity: 1;
            }
        }
    }
}
