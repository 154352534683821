/* antd 样式覆盖 */

.ant-dropdown-menu-root {
    background-color: black;
}
.ant-dropdown-menu-title-content {
    color: #fff;
}
.ant-dropdown-menu-item img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.ant-dropdown-menu-item-active {
    background-color: #2d3436 !important;
}

.ant-modal-content {
    background: #0e1220 !important;
    border: 1px solid #364262;
    border-radius: unset;
}
.ant-btn {
    border-color: #34498f;
}
.ant-btn:hover {
    color: unset;
    border-color: #4860af;
}
.ant-btn-primary {
    background: #34498f;
    border-color: #34498f;
}
.ant-btn-primary:hover {
    /* color: white; */
    background: #4860af;
    border-color: #4860af;
}
.ant-modal-mask {
    backdrop-filter: blur(10px) brightness(0.8);
    -webkit-backdrop-filter: blur(10px) brightness(0.8);
    background-color: unset;
}
