@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: hidden;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom -180px center;
    background-size: 100% auto;
}

.content {
    margin: 160px auto 50px;
    width: 100%;
    max-width: 1200px;

    .flexBox {
        height: 400px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .right {
            margin-left: 20px;
            // width: 400px;
            width: 35%;
            // background-color: orange;
            display: flex;
            flex-direction: column;
            // justify-content: flex-start;
            align-items: stretch;
            .title {
                font-size: 36px;
                margin-bottom: 36px;
            }
            .subTitle {
                font-size: 20px;
                margin-bottom: 12px;
            }
            .desc {
                flex: 1;
                font-family: ShentoxLight;
                font-size: 16px;
                line-height: 1.2;
            }

            .btnGroup {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                margin: auto -12px;
                .btn {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    // 239 × 97
                    width: 239px;
                    height: 97px;
                    margin: auto 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    // opacity: 0.9;
                    // transition: opacity 150ms ease-in-out;
                    // &:hover {
                    //     opacity: 1;
                    // }
                    span {
                        color: #69e8ff;
                        font-size: 20px;
                        transform: translateX(24px);
                    }
                }
            }
        }
    }
    .footer {
        border-top: 1px solid #b1f3ff;
        margin-top: 60px;
    }
}
