/* .react-tabs {
}
.react-tabs__tab-list {
  border: none;
}
.react-tabs__tab {
  border: none;
  border-radius: 0;
  background: #000;
  padding: 10px 25px;
}
.react-tabs__tab::after {
  display: none;
}
.react-tabs__tab::before{
    content: "";
    position: absolute;
    border-top: 1px solid #A1A1A1;
    border-right: 1px solid #A1A1A1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: perspective(.5em) rotateX(3deg) scale(1.3);
    transform-origin: bottom;
 }
.react-tabs__tab:first-child::before {
  border-left: 1px solid #A1A1A1;
} */

.react-tabs__tab--selected {
  color: #fff;
  background: transparent;
  z-index: 10;
}
/* .react-tabs__tab--selected::before {
  z-index: 2;
  border-left: 1px solid #A1A1A1;
  border-bottom: none;
} */
