.spk-nav {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    margin-top: 24px;
    left: 0;
    width: 100vw;
    padding: 0 24px;

    // height: 50px;
    // width: 100%;
    z-index: 1;
    display: flex;
    .spk-nav--left {
        // padding-bottom: 10px;
        display: flex;
        // align-items: baseline;
        .logo {
            // 627 x 93
            // width: 236px;
            // height: 35px;
            width: 169px;
            height: 25px;
            margin-bottom: 6px;
            display: block;
            // background: url('../../assets/imgs/logo.png') no-repeat;
            background-image: url('../../assets/imgs/logo.png');
            background-size: contain;
        }
        ul {
            display: flex;
            align-items: center;

            // font-size: 20px;
            // margin-top: 15px;
            margin: 0;
            padding: 0;
            list-style: none;
            // background-color: #95a9ff;
            li {
                // cursor: pointer;
                // margin-right: 20px;
                // background-color: orchid;

                &:first-child {
                    // margin-left: 40px;
                }
                &:hover {
                    opacity: 0.8;
                }
                + li {
                    margin-left: 24px;
                }
                a {
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    line-height: 20px;
                    line-height: 1;
                    font-family: ShentoxRegular;
                }
            }
        }
    }
    .spk-nav--right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
            // background-color: orange;
            background-image: url(../../assets/imgs/blank.png);
            // background-image: url(../../assets/imgs/header-right-btn-bg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 8px 16px;
            cursor: pointer;
            transition: background 150ms;
            &:hover {
                background-image: url(../../assets/imgs/header-right-btn-bg.png);
                color: #fff;
            }
        }
        .divider {
            width: 1px;
            height: 16px;
            background-color: #e6e6e6;
            margin: 0 8px;
        }
    }
}
.footNav {
    display: none;
}
.navBtn {
    display: none;
    margin-left: 20px;
}

@media screen and (max-width: 767px) {
    .spk-nav .spk-nav--left {
        ul {
            display: none;
        }

        .logo {
            width: 106px;
            height: 16px;
        }
    }

    .footNav {
        display: block;
        position: fixed;
        padding: 16px;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 111;
        background: rgba($color: #000000, $alpha: 0.4);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        &::after {
            // content: '.';
            height: 0;
            display: block;
            clear: both;
        }
    }

    .footNavItem {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 0;
        padding: 15px 0;
        a {
            display: block;
            width: 100%;
        }
        &::before {
            margin-right: 10px;
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #95a9ff;
            transform: rotate(45deg);
        }
    }
    .navBtn {
        display: block;
    }
    .hidden {
        display: none;
    }
}

@media screen and (max-width: 2600px) {
    .spk-nav .spk-nav--left {
        ul {
            font-size: 36px;
        }
    }
    // .spk-nav {
    //     transform: scale(1.2);
    // }
}

@media screen and (max-width: 1100px) {
    .spk-nav .spk-nav--left {
        .logo {
            width: 156px;
            height: 23px;
        }
    }
}

@media screen and (max-width: 1920px) {
    .spk-nav .spk-nav--left {
        ul {
            font-size: 24px;
        }
    }
}
