@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: hidden;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom -180px center;
    background-size: 100% auto;
}

.content {
    margin: 160px auto 50px;
    width: 100%;
    max-width: 1200px;

    .flexBox {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .flexItem {
            flex: 1;
            border: 2px solid transparent;
            transition: all 150ms ease;
            padding: 6px;
            margin: 6px;
            cursor: pointer;
            user-select: none;
            height: 600px;
            &:hover {
                border-color: rgb(119, 194, 223);
                box-shadow: 0 0 6px rgb(119, 194, 223), 0px 0px 6px rgb(119, 194, 223) inset;
                .wrapBox {
                    backdrop-filter: brightness(100%);
                }
            }
            .flexInner {
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 100%;
                height: 100%;
            }
            .wrapBox {
                transition: all 150ms ease;
                backdrop-filter: brightness(60%);
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-self: stretch;
            }
        }
        .titleBox {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            margin-bottom: 50px;
            filter: drop-shadow(0px 0px 6px black);
            .logo {
                width: 70px;
                height: 70px;
                object-fit: contain;
            }
            .title {
                font-size: 30px;
                text-transform: uppercase;
            }
            .dividing {
                width: 2px;
                height: 60px;
                background: linear-gradient(to top, #adbdff, #ff9bc9, #ffe693);
                margin-right: 12px;
            }
        }
        .describe {
            padding: 20px;
            font-size: 20px;
            font-family: ShentoxLight;
            height: 160px;
            line-height: 1.2;
            filter: drop-shadow(0px 0px 6px black);
        }
    }
}
