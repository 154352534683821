@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: auto;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom -180px center;
    background-size: 100% auto;
}

.banner {
    margin: 160px auto 50px;
    width: 100%;
    max-width: 1200px;
    // background-color: blueviolet;
    display: flex;
    align-content: stretch;
    .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // padding: 20px;
        .title {
            font-size: 50px;
            line-height: 1.5;
            font-family: ShentoxMedium;
            font-weight: bold;
            color: #ffffff;
            display: flex;
            align-items: center;
        }
        .subTitle {
            margin-top: 20px;
            // width: 60%;
            // font-size: 20px;
            line-height: 1.5;
            font-family: ShentoxMedium;
            color: #ffffff;
        }
    }
    .right {
        width: 30vw;
        // height: 18vw;
        // background: #171717;
        // border: 1px solid #707070;
        padding: 30px;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        .phase {
            width: 288px;
            height: 70px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            font-size: 32px;
            font-family: ShentoxMedium;
            font-weight: bold;
            color: #88f6ff;
            text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.68);
            font-style: italic;
            padding-left: 60px;
            padding-top: 10px;
            transform: translateX(-36px);
        }
        .totlaBox {
            border: 1px solid rgba(136, 246, 255, 1);
            width: 100%;
            height: 70px;
            border-radius: 8px;
            margin-top: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .totlaItem {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                .label {
                    // font-size: 18px;
                    color: #88f6ff;
                }
            }
            .divider {
                height: 60%;
                width: 1px;
                background-color: rgba(136, 246, 255, 1);
            }
        }
    }
}

h3.title {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    // padding: 0 20px;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
}

.stakeGroup {
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .stakeItem {
        // width: calc(637px * 0.8);
        // height: calc(937px * 0.8);
        width: 375px;
        height: 551px;
        padding: 12px;
        // background: url('@/assets/imgs/stake/stake1-bg.png') no-repeat 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .titleGroup {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            // 不换行
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .title {
                flex: 1;
                margin: auto 20px;
                font-size: 32px;
                font-family: ShentoxMedium;
                font-weight: bold;
                color: #b4c0db;
            }
            .subTitle {
                // width: 131px;
                margin: auto 20px;
                font-size: 16px;
                font-family: ShentoxMedium;
                font-weight: bold;
                color: #84a1ff;
            }
        }
        .apy {
            width: 100%;
            height: 30px;
            padding-left: 20px;
            display: flex;
            // justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: ShentoxMedium;
            // font-weight: 400;
            font-style: italic;
            color: #84a1ff;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.54);
            .percent {
                font-size: 28px;
                font-weight: bold;
                margin-left: 16px;
            }
        }
        .infos {
            height: 140px;
            display: flex;
            align-items: flex-end;
            padding-left: 20px;
            padding-bottom: 20px;
            font-size: 22px;
            font-weight: bold;
            // justify-content: space-between;
            // align-items: stretch;
            .left {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .discTitle {
                    font-size: 20px;
                    color: #84a1ff;
                    margin-left: 20px;
                    margin-bottom: 20px;
                }
                .disc {
                    font-size: 14px;

                    margin-left: 20px;
                    line-height: 1.2;
                }
            }
            .right {
                width: 30%;
                padding-right: 20px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .dateInfos {
            // flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            // padding: 30px 0;
            padding-top: 24px;
            .item {
                // background-color: rgba(205, 21, 31, 0.33);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 40px;
                padding: 0 20px;
                + .item {
                    margin-top: 6px;
                }
                font-size: 14px;
                font-family: ShentoxMedium;
                // font-weight: 400;
                color: rgba($color: #fff, $alpha: 0.75);

                .label {
                    flex: 1;
                    color: #fff;
                }
            }
            .tokenIcon {
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
        .footer {
            // height: 80px;
            flex: 1;
            // background-color: orange;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            .btn {
                background-color: rgba(75, 90, 172, 1);
                width: 180px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-family: ShentoxMedium;
                font-weight: bold;
                color: #ffffff;
                cursor: pointer;
                user-select: none;
                transition: background 0.15s ease;
                &:hover {
                    background-color: rgb(93, 110, 204);
                }
                &.disabled {
                    cursor: not-allowed;
                    color: #969696;
                    background-color: rgba(95, 95, 95, 0.8);
                }
            }
        }

        &.pool-2 {
            .titleGroup .title {
                color: #b986ff;
            }
            .apy {
                color: #ff9c84;
            }
            .infos .left .discTitle {
                color: #ff9c84;
            }
        }
        &.pool-3 {
            .titleGroup .title {
                color: #ffe202;
            }
            .apy {
                color: #84fed9;
            }
            .infos .left .discTitle {
                color: #84fed9;
            }
        }
    }
}
