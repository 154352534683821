@import '../../styles/index.scss';

.app {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-color: #000;
    background-image: url(../../assets/imgs/bg-loot.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nav {
    margin-bottom: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 28px;
}

.logo {
    display: block;
    width: 47px;
    height: 41px;
    background: url('../../assets/imgs/logo.png') no-repeat;
    background-size: cover;
}
.twlogo {
    display: block;
    width: 36px;
    height: 36px;
    background-size: cover;
}
.tglogo {
    display: block;
    width: 41px;
    height: 41px;
    background-size: cover;
}
.container {
    display: flex;
    justify-content: space-between;
    padding: 0 75px;
}

.left {
    width: 41vw;
    padding-right: 16px;
}

.h1 {
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    font-family: ShentoxBold;
    font-size: 52px;
    color: #ffffff;
    &::before {
        margin-right: 17px;
        content: '';
        display: block;
        width: 71px;
        height: 46px;
        background: url('../../assets/imgs/icon-title.png') no-repeat;
        background-size: cover;
    }
}

.h2 {
    margin-bottom: 15px;
    font-family: ShentoxMedium;
    font-size: 31px;
    color: #ffffff;
}

.h2MT {
    margin-top: 26px;
}

.word {
    font-size: 15px;
    font-family: ShentoxMedium;
    color: #ffffff;
    line-height: 19px;
}

.wordColor {
    color: #ff8700;
}

.modules {
    margin-top: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.image1 {
    width: 11vw;
    height: 23vw;
    object-fit: contain;
}

.image2 {
    width: 24vw;
    height: 17vw;
    object-fit: contain;
}

.right {
    padding-top: 32px;
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
    width: 51vw;
    height: 924px;
    background: rgba($color: #000000, $alpha: 0.42);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, #003bdd 0%, #00d2fe 100%);
        opacity: 0.6;
    }
}

.h3 {
    margin-bottom: 30px;
    font-family: ShentoxBold;
    font-size: 52px;
    color: #ffffff;
}
.content {
    display: flex;
}
.contentItem {
    width: 50%;
}

.label {
    position: relative;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    &::before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: -10px;
        width: 6px;
        height: 27px;
        border-top: 2px solid #d1f7ff;
        border-bottom: 2px solid #d1f7ff;
        box-sizing: border-box;
    }
}

.labelText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 131px;
    height: 27px;
    background: rgba($color: #00d2fe, $alpha: 0.5);
    border: 1px solid rgba($color: #00d2fe, $alpha: 0.5);
    font-family: ShentoxMedium;
    font-size: 21px;
    color: #fff;
}

.list {
    &::after {
        content: '.';
        height: 0;
        display: block;
        clear: both;
    }
}
.listItem {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-family: ShentoxMedium;
    float: left;
    color: #64869a;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    &::before {
        margin-right: 5px;
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background: #64869a;
        transform: rotate(45deg);
        transition: all 0.3s ease-in-out;
    }
}

.listItemActive {
    color: #fff;
    &::before {
        background: #d1f7ff;
        box-shadow: 0 0 8px #d1f7ff;
    }
}

.listItemML40 {
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
}

.listItemML20 {
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}

.itemBM {
    margin-bottom: 32px;
}

.listItemBox {
    margin-bottom: 29px;
    display: flex;
    height: 81px;
    cursor: pointer;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.listItemIcon {
    margin-right: 38px;
    width: 71px;
    height: 81px;
    transition: all 0.3s ease-in-out;
}

.listItemIcon1 {
    background: url('../../assets/imgs/camp-1.png') no-repeat;
    background-size: cover;
}
.listItemIcon2 {
    background: url('../../assets/imgs/camp-2.png') no-repeat;
    background-size: cover;
}
.listItemIcon3 {
    background: url('../../assets/imgs/camp-3.png') no-repeat;
    background-size: cover;
}
.listItemIcon4 {
    background: url('../../assets/imgs/camp-4.png') no-repeat;
    background-size: cover;
}

.listItemIcon1.listItemIconActive {
    background: url('../../assets/imgs/camp-1-light.png') no-repeat;
    background-size: cover;
}
.listItemIcon2.listItemIconActive {
    background: url('../../assets/imgs/camp-2-light.png') no-repeat;
    background-size: cover;
}
.listItemIcon3.listItemIconActive {
    background: url('../../assets/imgs/camp-3-light.png') no-repeat;
    background-size: cover;
}
.listItemIcon4.listItemIconActive {
    background: url('../../assets/imgs/camp-4-light.png') no-repeat;
    background-size: cover;
}

.point {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: ShentoxMedium;
    color: #ffffff;
}
.currentPoint {
    display: block;
    color: #00ff3b;
    width: 48px;
    height: 50px;
    background: url(../../assets/imgs/icon-number.png) no-repeat;
    background-size: cover;
    text-align: center;
    line-height: 50px;
}

.slider {
    margin-bottom: 32px;
}

.sliderItem {
    display: flex;
    align-items: center;
    & + & {
        margin-top: 22px;
    }
}
.sliderItembot {
    display: flex;
    align-items: center;
    & + & {
        margin-top: 22px;
    }
    padding: 0 75px;
}

.sliderItemHd {
    margin-right: 12px;
    width: 26px;
    height: 26px;
    background: #00d2fe;
}

.sliderItemBd {
    font-size: 15px;
    font-family: ShentoxMedium;
    line-height: 1;
    color: #ffffff;
    flex: 1;
}

.sliderItem1 {
    .sliderItemHd {
        background: url('../../assets/imgs/Attributes-1.png') no-repeat;
        background-size: cover;
    }
}

.sliderItem1 {
    .sliderItemHd {
        background: url('../../assets/imgs/Attributes-1.png') no-repeat;
        background-size: cover;
    }
}

.sliderItem2 {
    .sliderItemHd {
        background: url('../../assets/imgs/Attributes-2.png') no-repeat;
        background-size: cover;
    }
}

.sliderItem3 {
    .sliderItemHd {
        background: url('../../assets/imgs/Attributes-3.png') no-repeat;
        background-size: cover;
    }
}

.sliderItem4 {
    .sliderItemHd {
        background: url('../../assets/imgs/Attributes-4.png') no-repeat;
        background-size: cover;
    }
}

.sliderItem5 {
    .sliderItemHd {
        background: url('../../assets/imgs/Attributes-5.png') no-repeat;
        background-size: cover;
    }
}

.sliderItemFt {
    display: flex;
    align-items: center;
}

:global {
    .horizontalSlider {
        position: relative;
        width: 7vw;
        height: 14px;
        border: 1px solid #64869a;
    }

    .thumb {
        position: absolute;
        top: -3px;
        width: 3px;
        height: 18px;
        background: #d1f7ff;
    }

    .track-0 {
        height: 14px;
        background: #2ffbfb;
        box-shadow: 0 0 8px #2ffbfb;
    }

    .ReactModal__Overlay {
        z-index: 1000;
    }
}
.t {
    margin-right: 4px;
    width: 2px;
    height: 14px;
    background: #d1f7ff;
}

.b {
    text-align: right;
    margin-left: 10px;
    font-family: ShentoxBold;
    font-size: 18px;
    color: #64869a;
    width: 47px;
}

.white {
    color: #fff;
}

.labelBtn {
    margin-bottom: 14px;
    .labelText {
        width: 212px;
    }
}

.input {
    width: 211px;
    height: 27px;
    border: 1px solid rgba($color: #00d2fe, $alpha: 0.5);
    background: transparent;
    color: #fff;
    padding: 0 5px;
    color: 15px;
    caret-color: rgba($color: #00d2fe, $alpha: 0.5);
    text-align: center;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: rgba($color: #00d2fe, $alpha: 0.5);
        text-align: center;
        font-family: ShentoxLight;
    }
}

.tip {
    color: rgba($color: #00d2fe, $alpha: 0.5);
    margin-bottom: 14px;
    font-size: 12px;
}
.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 211px;
    height: 27px;
    background: rgba($color: #00d2fe, $alpha: 0.5);
    color: #fff;
    font-family: ShentoxMedium;
    font-size: 21px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    &:hover {
        background: #2ffbfb;
        box-shadow: 0 0 8px #2ffbfb;
    }
    &::before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: -10px;
        width: 6px;
        height: 27px;
        border-top: 2px solid #d1f7ff;
        border-bottom: 2px solid #d1f7ff;
        box-sizing: border-box;
    }
    &::after {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        right: -10px;
        width: 6px;
        height: 27px;
        border-top: 2px solid #d1f7ff;
        border-bottom: 2px solid #d1f7ff;
        box-sizing: border-box;
    }
}

.itemBM2 {
    margin-bottom: 250px;
}

.modalChoseBtn {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 24px;
    cursor: pointer;
    width: 1.125em;
    height: 1em;
}

.modalContent {
    padding: 16px 32px;
    img {
        margin-bottom: 16px;
        display: block;
        width: 500px;
    }
}

.opensea {
    text-align: center;
    a {
        color: #000;
    }
}

@media screen and (max-width: 1280px) {
    .app {
        padding-bottom: 32px;
    }
    .nav {
        margin-bottom: 17px;
        display: block;
    }
    .container {
        display: block;
        padding: 0 23px;
    }

    .left {
        width: 100%;
        padding-bottom: 32px;
        padding-right: 0;
    }

    .right {
        width: 100%;
        height: auto;
        padding: 24px 15px;
    }

    .h1 {
        font-size: 30px;
        &::before {
            margin-right: 9px;
            width: 35px;
            height: 23px;
        }
    }

    .h2 {
        margin-bottom: 11px;
        font-size: 15px;
    }

    .image1 {
        width: 78px;
        height: 159px;
    }

    .image2 {
        width: 141px;
        height: 101px;
        object-fit: contain;
    }

    .word {
        font-size: 13px;
        line-height: 14px;
    }

    .h3 {
        margin-bottom: 37px;
        font-size: 30px;
    }

    .content {
        display: block;
    }
    .contentItem {
        width: 100%;
    }

    .listItem {
        font-size: 15px;
    }

    .listItemML20 {
        width: 50%;
        margin-right: 0;
    }

    .listItemBox {
        float: left;
        height: 140px;
        display: block;
        width: 50%;
        .listItem {
            width: 100%;
            justify-content: center;
        }
    }

    .listItemIcon {
        margin: 0 auto 6px;
    }

    .sliderItemHd {
        margin-right: 7px;
        width: 15px;
        height: 15px;
    }

    .sliderItemBd {
        font-size: 14px;
    }

    .itemBM2 {
        margin-bottom: 64px;
    }

    .b {
        margin-left: 5px;
        font-size: 9px;
        width: 19px;
    }

    .mb16 {
        margin: 16px auto;
    }

    .modalContent {
        padding: 8px 16px;
        img {
            margin-bottom: 16px;
            display: block;
            width: 250px;
        }
    }

    :global {
        .horizontalSlider {
            width: 123px;
            height: 14px;
        }

        .thumb {
            position: absolute;
            top: -3px;
            width: 3px;
            height: 18px;
            background: #d1f7ff;
        }

        .track-0 {
            height: 14px;
            background: #2ffbfb;
            box-shadow: 0 0 8px #2ffbfb;
        }
    }
}
