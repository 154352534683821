/* carousel样式覆盖 */

.ant-carousel .slick-dots {
    margin-right: 24px !important;
    margin-bottom: 12px !important;
    justify-content: flex-end !important;
}
.ant-carousel .slick-dots li button {
    height: 6px;
}
