@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: hidden;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom -180px center;
    background-size: 100% auto;
}

.content {
    margin: 160px auto 50px;
    width: 100%;
    max-width: 1200px;
    .contentStyle {
        height: 600px;
        color: #fff;
        text-align: center;
        background: #364d79;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .title {
        font-family: ShentoxBold;
        padding: 0 40px;
        text-align: left;
        font-size: 60px;
    }
    .subTitle {
        padding: 0 40px;
        text-align: left;
        font-family: ShentoxLight;
    }
}
