.NewsList {
    position: relative;
    height: 100vh;
    overflow-y: auto;
    color: #fff;
    background-color: #000;
    font-family: ShentoxMedium;

    .gameNewsImg {
        position: relative;
        top: 120px;
        // min-width: 80vw;
        height: 500px;
        margin: 0 20px;
        background: url('../../assets/imgs/news/home-banner.png');

        > p {
            position: absolute;
            font-size: 50px;
            right: 30px;
            bottom: 30px;
        }
    }

    .title {
        position: absolute;
        top: 650px;
        right: 10vw;
        left: 10vw;
        width: 80vw;
        margin: 30px auto;
        h2 {
            font-size: 50px;
            line-height: 2.2;
        }

        li {
            // width: 55vw;

            margin-bottom: 40px;
        }
        li:nth-child(odd) {
            padding-right: 200px;
        }
        li:nth-child(even) {
            padding-left: 200px;
        }
        .content {
            display: flex;
            align-items: center;
            // justify-content: space-evenly;
        }

        .contentDetail {
            display: flex;

            align-content: space-between;
            flex-wrap: wrap;
            min-width: 430px;
            max-width: 1280px;
            min-height: 180px;
            margin-left: 40px;
        }

        .date {
            font-size: 12px;
        }

        h3 {
            width: 100%;
            font-size: 24px;
            line-height: 1.8;
        }

        .contents {
            font-size: 14px;
            line-height: 1.5;
        }

        .details {
            width: 100px;
            font-size: 18px;
            color: #ff2b2b;
            margin-top: 10px;
            position: relative;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 80px;
                width: 0;
                height: 0;
                border-left: 10px solid white;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .NewsList {
        .gameNewsImg {
            height: 109px;
            background-size: 100%;
            > p {
                position: absolute;
                font-size: 18px;
                right: 20px;
                bottom: 20px;
            }
        }

        .title {
            position: absolute;
            top: 250px;
            right: 5vw;
            left: 5vw;
            width: 90vw;
            margin: 30px auto;
            h2 {
                font-size: 18px;
                line-height: 2.2;
            }

            li {
                // width: 55vw;

                margin-bottom: 40px;
            }
            li:nth-child(odd) {
                padding-right: 0;
            }
            li:nth-child(even) {
                padding-left: 0;
            }
            .content {
                display: flex;
                align-items: center;
                flex-direction: column;
                // justify-content: space-evenly;
            }

            .contentDetail {
                display: flex;

                align-content: space-between;
                margin-top: 10px;
                padding-bottom: 10px;
                flex-wrap: wrap;
                min-width: 350px;
                max-width: 350px;
                min-height: 50px;
                margin-left: 0;
                align-content: baseline;
                border-bottom: 1px solid #ccc;
            }

            .date {
                font-size: 12px;
            }

            h3 {
                width: 100%;
                font-size: 16px;
                line-height: 1.8;
            }

            .contents {
                font-size: 14px;
                line-height: 1.5;
            }

            .details {
                width: 100px;
                font-size: 18px;
                color: #ff2b2b;
                margin-top: 10px;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 80px;
                    width: 0;
                    height: 0;
                    border-left: 10px solid white;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                }
            }
        }
    }
}
