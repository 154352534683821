.beta-now {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 800px;
    height: 100vh;
    padding-top: 40px;
    margin: 0 auto;
    text-align: center;

    color: #fff;
    font-family: ShentoxMedium;
    background-color: #000;
    .beta-now-content {
        margin-top: 40px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    .beta-now-btn {
        display: flex;
        justify-content: space-around;
        margin-top: 40px;
    }

    button {
        height: 40px;
        text-align: center;
        padding: 2px 30px;
        background-color: rgba(0, 0, 0, 0.3);
        border: 3px solid rgba(144, 170, 246, 0.3);
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        color: #fff;
        transition: all 150ms;
        font-family: ShentoxMedium;
        background-color: #000;
        &:hover {
            color: #ccc;
        }
    }
    a {
        height: 40px;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        padding: 2px 30px;
        background-color: rgba(0, 0, 0, 0.3);
        border: 3px solid rgba(144, 170, 246, 0.3);
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        color: #fff;
        transition: all 150ms;
        font-family: ShentoxMedium;
        background-color: #000;
        &:hover {
            color: #ccc;
        }
    }
}
