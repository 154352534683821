// @use "sass:math";
// @function responsive($number, $index) {
//   @return $number * (1 - math.div(0.5, 4) * $index);
// }
/*
@keyframes heart{
  from{transform:translate(0,0)}
  to{transform:translate(0, 12px)}
}*/

.app {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../../assets/imgs/hallo/logo_spark.png') no-repeat 20px 20px,
    url('../../assets/imgs/hallo/pum-l.png') no-repeat left bottom,
    url('../../assets/imgs/hallo/pum-r.png') no-repeat right bottom,
    url('../../assets/imgs/hallo/halloween_bg.png') no-repeat;
    background-size: 273px auto,
    200px 120px,
    200px 120px,
    cover;
    overflow-x: auto;
    overflow-y: hidden;
    color: white;
    font-family: ShentoxMedium;
}
.conBtn {
  z-index: 1;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 200px;
  height: 60px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
  background: url('../../assets/imgs/hallo/btn.png') no-repeat center center;
  background-size:  100% 100%;
  white-space: nowrap;
  padding: 0 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main {
  width: 100%;
  min-width: 1500px;
  overflow-x: auto;
  height: 100%;
  display: flex;
  justify-content: center;
}
.ctx {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 40px;
  }
  .box {
    width: 460px;
    height: 500px;
    background: url('../../assets/imgs/hallo/ha_box.png') no-repeat center center;
    background-size: 460px 500px;
  }
  .btn {
    background: url('../../assets/imgs/hallo/hallo_button.png') no-repeat center center;
    width: 500px;
    height: 120px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    &:active{
      opacity: .8;
    }
    span:first-child{
      font-size: 36px;
      flex: 1;
      text-align: center;
    }
    .boxNum{
      font-size: 24px;
      width: 150px;
      text-align: center;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: ' ';
        background-color: #fef0f0;
        top: 5px;
        left: 40px;
        width: 1px;
        height: 24px;
      }
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
.store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 900px;
  margin-left: 50px;
  .title {
    background: url('../../assets/imgs/hallo/ha_ban.png') no-repeat center center;
    height:80px;
    width: 100%;
    line-height: 80px;
    background-size: 100% 100%;
    font-size: 32px;
    padding-left: 15px;
  }
  .cardList {
    height: 60vh;
    width: 100%;
    background: rgba(241, 196, 15, .1);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px;
    overflow-y: auto;
    .card {
      cursor: pointer;
      background-size: 144px 200px;
      width: 20%;
      height: 200px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px #b38c2e;
      background: #f5d5569c
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      border-radius: 0;
      background: #b38c2e33;
    }
  }
}
.link {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 280px;
  height: 50px;
  cursor: pointer;
}
.poorModal{
  background: #fef0f0;
  border: 8px solid #e5ad14;
  padding: 20px;
  color: #F56C6C;
}
.haModal{
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: white;
  font-family: ShentoxMedium;
  text-align: center;
  .modalClose{
    width: 50px;
    height: 50px;
    text-align: center;
    position: relative;
    float: right;
    cursor: pointer;
    &::before,
    &::after {
      position: absolute;
      content: ' ';
      background-color: #fef0f0;
      top: 0px;
      right: 20px;
      width: 3px;
      height: 50px;
    }
    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
  .modalBox{
    margin: 0 auto;
    background: url('../../assets/imgs/hallo/ha_box.png') no-repeat center center;
    background-size: auto 100%;
    height: 70%;
  }
  .modalFoot {
    display: flex;
    justify-content: center;
    .modalBtn{
      cursor: pointer;
      width: 285px;
      height: 80px;
      line-height: 80px;
      display: flex;
      justify-content: space-between;
      padding: 0 45px 0 25px;
      background: url('../../assets/imgs/hallo/modal_btn.png') no-repeat center center;
      background-size: 100% 100%;
      .boxNum{
        font-size: 18px;
        text-align: center;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          content: ' ';
          background-color: #fef0f0;
          top: 34px;
          left: -15px;
          width: 1px;
          height: 12px;
        }
        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.displayCardModal{
  width: 350px;
  height: 500px;
  animation: heart 1.3s ease-in-out 2.7s infinite alternate;
}

.cardModalCtx {
  width: 100%;
  height: 100%;
  .modalClose{
    width: 50px;
    height: 50px;
    text-align: center;
    position: relative;
    float: right;
    cursor: pointer;
    &::before,
    &::after {
      position: absolute;
      content: ' ';
      background-color: #fef0f0;
      top: 10px;
      right: 30px;
      width: 3px;
      height: 50px;
    }
    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
  .cardCtx{
    width: 100%;
    padding-top: 150px;
    text-align: center;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    .cardItem {
      display: inline-block;
      width: 280px;
      height: 400px;
      animation: heart 1.3s ease-in-out 2.7s infinite alternate;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px #b38c2e;
      background: #be850066;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      border-radius: 0;
      background: #b38c2e33;

    }
  }
}
.videoModalCtx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .video  {
    width: 60vw;
    height: auto;
    opacity: .9;
    object-fit:fill;
    // padding: 10%;
  }
}
.tw{
  width: 100px;
  height: 100px;
  background: url('../../assets/imgs/hallo/twitter.png') no-repeat center center;
  background-size: 100px 100px;
  position: absolute;
  right: 220px;
  bottom: 10px;
}
.teli{
  width: 100px;
  height: 100px;
  background: url('../../assets/imgs/hallo/teli.png') no-repeat center center;
  background-size: 100px 100px;
  position: absolute;
  right: 330px;
  bottom: 10px;

}
@media screen and (max-width: 1480px) {
}
@media screen and (max-width: 768px) {
  .app {
      background: url('../../assets/imgs/hallo/ha_logo_mb.png') no-repeat 10px 10px,
      url('../../assets/imgs/hallo/pum-r.png') no-repeat right bottom,
      url('../../assets/imgs/hallo/ha_bg_mb2.png') no-repeat center bottom,
      url('../../assets/imgs/hallo/ha_bg_mb.png') no-repeat center center;
      background-size: 101px auto,
      150px 90px,
      100% auto,
      cover;
  }

  .conBtn {
    z-index: 1;
    cursor: pointer;
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 150px;
    height: 45px;
    line-height: 37.5px;
    font-size: 15px;
    text-align: center;
    background: url('../../assets/imgs/hallo/btn.png') no-repeat center center;
    background-size:  100% 100%;
    white-space: nowrap;
    padding: 0 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .main {
    width: 100%;
    min-width: 100%;
    height: 100%;
    display: block;
  }
  .ctx {
    height: 50%;
    position: relative;
    text-align: center;
    padding-top: 100px;
    .title {
      font-size: 20px;
    }
    .box {
      position: static;
      width: 100%;
      height: 40vh;
      background: url('../../assets/imgs/hallo/ha_box_mb.png') no-repeat center center;
      background-size: auto 100%;
    }
    .btn {
      background: url('../../assets/imgs/hallo/hallo_button.png') no-repeat center center;
      width: 90vw;
      height: 100px;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      &:active{
        opacity: .8;
      }
      span:first-child{
        font-size: 20px;
        flex: 1;
        text-align: center;
      }
      .boxNum{
        width: 35%;
        text-align: center;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          content: ' ';
          background-color: #fef0f0;
          top: 7px;
          left: 40px;
          width: 1px;
          height: 20px;
        }
        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .store {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0px 15px;
    .title {
      background: url('../../assets/imgs/hallo/ha_ban.png') no-repeat center center;
      height:40px;
      width: 100%;
      line-height: 40px;
      background-size: 100% 100%;
      font-size: 20px;
      padding-left: 15px;
    }
    .cardList {
      height: auto;
      width: 100%;
      background: rgba(241, 196, 15, .1);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .card {
        background: url('../../assets/imgs/hallo/c.png') no-repeat center center;
        background-size: 100% 100%;
        width: 36vw;
        height: 50vw;
        margin: 10px 0;
      }
    }
  }
  .haModal{
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 20px;
    color: white;
    font-family: ShentoxMedium;
    text-align: center;
    .modalClose{
      width: 50px;
      height: 50px;
      text-align: center;
      position: relative;
      float: right;
      cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        content: ' ';
        background-color: #fef0f0;
        top: 0px;
        right: 20px;
        width: 3px;
        height: 50px;
      }
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
    .modalBox{
      margin: 0 auto;
      background: url('../../assets/imgs/hallo/ha_box.png') no-repeat center center;
      background-size: 100% auto;
      height: 60%;
      width: 100%;
    }
    .modalFoot {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .modalBtn{
        cursor: pointer;
        width: 285px;
        height: 80px;
        line-height: 80px;
        display: flex;
        justify-content: space-between;
        padding: 0 45px 0 25px;
        background: url('../../assets/imgs/hallo/modal_btn.png') no-repeat center center;
        background-size: 100% 100%;
        .boxNum{
          font-size: 18px;
          text-align: center;
          position: relative;
          &::before,
          &::after {
            position: absolute;
            content: ' ';
            background-color: #fef0f0;
            top: 34px;
            left: -15px;
            width: 1px;
            height: 12px;
          }
          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .link {
    width: 150px;
    height: 37px;
  }
  .tw{
    width: 80px;
    height: 80px;
    background: url('../../assets/imgs/hallo/twitter.png') no-repeat center center;
    background-size: 80px 80px;
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
  .teli{
    width: 80px;
    height: 80px;
    background: url('../../assets/imgs/hallo/teli.png') no-repeat center center;
    background-size: 80px 80px;
    position: absolute;
    left: 100px;
    bottom: 10px;

  }

  .videoModalCtx {
    .video  {
      width: 90vw;
      height: auto;
      opacity: .9;
      object-fit:fill;
      // padding: 10%;
    }
  }

  .displayCardModal{
    width: 70vw;
    height: 100vw;
    animation: heart 1.3s ease-in-out 2.7s infinite alternate;
  }
}
