.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: hidden;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom -180px center;
    background-size: 100% auto;
    background-image: url('../../assets/imgs/page-bg.png');
    position: relative;

    button {
        width: 143px;
        height: 30px;
        // margin-left: 20px;
        color: white;
        background: #15203c;
        border: 1px solid #3b5abf;
        opacity: 1;
        border-radius: 6px;
        outline: none;

        &:hover {
            background: #3b5abf;
        }
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;

        display: flex;
        justify-content: space-between;
        padding: 30px;
        transform: translate(-50%, -50%) scale(1.7);

        .contentLeft {
            margin-right: 20px;
            .contentTip {
                display: inline-block;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 20px;
                color: #84a1ff;
                background: rgba(87, 120, 226, 0.22);
            }

            button {
                font-size: 12px;
                margin-left: 20px;
            }

            .title {
                font-size: 30px;
                margin-bottom: 20px;
            }

            li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                // justify-content: center;

                span {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    margin-right: 20px;
                    background: #131a32;
                    border-radius: 50%;
                    opacity: 1;
                }
            }
        }

        .contentRight {
            // background-color: peru;
            display: flex;
            width: 382px;
            // height: 215px;
            padding: 10px;
            padding-top: 20px;
            // padding-right: 5px;
            background: url('../../assets/imgs/nft-bg.png') no-repeat;
            background-size: 100% 130%;

            .left {
                // display: flex;
                padding: 10px;
                width: 160px;
                background: #262b39;

                // align-items: center;

                p {
                    color: #808080;
                    font-size: 12px;
                    // margin-bottom: 10px;
                    span {
                        color: #84a1ff;
                    }
                }
                img {
                    display: flex;
                    margin: 10px 0;
                }
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-left: 10px;
                padding: 10px;
                background: #262b39;

                p {
                    // color: #808080;
                    color: #ffffff;
                    font-size: 14px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .addFire {
                    width: 100%;
                    padding: 10px;
                    border: 2px dashed #404b6e;
                    border-radius: 5px;
                    text-align: right;
                }
                .FireNum {
                    font-size: 24px;
                }
                // img {
                //     display: flex;
                //     margin: 10px 0;
                // }
                button {
                    width: 100%;
                    height: 36px;
                }
            }
        }
    }

    .notContent {
        position: relative;
        height: 100vh;
        width: 100%;
        background: rgba($color: #000000, $alpha: 0.7);
    }

    .notify {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.7);
        display: flex;
        justify-content: space-between;
        width: 400px;
        height: 300px;
        padding: 30px;
        background: url('../../assets//imgs/vouchers-success-bg.png') no-repeat;
        background-size: 100%;

        .title {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 24px;

            p {
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                cursor: pointer;
            }
        }

        .content {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 180px;
            height: 100px;
            padding: 0;
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 450px) {
    .app {
        color: #ffffff;
        width: 100vw;
        min-width: 380px;
        overflow: hidden;
        background: #000;
        font-family: ShentoxMedium;
        min-height: 100vh;
        background-repeat: no-repeat;
        // background-position: bottom -180px center;
        background-size: 100% auto;
        background-position: bottom;
        background-image: url('../../assets/imgs/page-bg.png');
        position: relative;
        // height: 100vh;
        // overflow-y: scroll;

        button {
            width: 100%;
            height: 40px;
            // margin-left: 20px;
            color: white;
            // background: #15203c;
            background: #3b5abf;
            border: 1px solid #3b5abf;
            opacity: 1;
            border-radius: 6px;
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-wrap: wrap;
            align-content: stretch;
            // justify-content: space-between;
            // flex-direction: column-reverse;
            padding: 30px;
            height: calc(100vh - 60px);
            overflow-y: scroll;
            // background: purple;

            .contentLeft {
                margin-bottom: 20px;
                margin-right: 0;
                padding: 0 10px;
                padding-top: 30px;
                .contentTip {
                    display: inline-block;
                    padding: 5px 10px;
                    border-radius: 5px;
                    font-size: 20px;
                    color: #84a1ff;
                    background: rgba(87, 120, 226, 0.22);
                }

                button {
                    font-size: 16px;
                    margin-left: 0;
                }

                .title {
                    font-size: 30px;
                    margin-bottom: 20px;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    // justify-content: center;

                    span {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 25px;
                        height: 25px;
                        margin-right: 20px;
                        background: #131a32;
                        border-radius: 50%;
                        opacity: 1;
                    }

                    button {
                        display: block;
                    }
                }
            }

            .contentRight {
                // background-color: peru;
                display: flex;
                flex-wrap: wrap;
                width: 380px;
                padding: 20px 10px 10px;
                background: url('../../assets/imgs/nft-bg.png');
                background-size: 100% 130%;

                margin-bottom: 20px;
                // background-color: #1c202a;

                .left {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    width: 100%;
                    padding: 10px;
                    background: #262b39;
                    margin-bottom: 20px;
                    // align-items: center;

                    p {
                        text-align: center;
                        color: #808080;
                        font-size: 12px;
                        // margin-bottom: 10px;
                        span {
                            color: #84a1ff;
                        }
                    }
                    img {
                        display: flex;
                        margin: 10px auto;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-left: 0;
                    padding: 10px;
                    background: #262b39;

                    > div {
                        width: 100%;
                        text-align: center;
                    }

                    p {
                        // color: #808080;
                        color: #ffffff;
                        font-size: 14px;
                        margin: 10px 0;
                        cursor: pointer;
                    }

                    .addFire {
                        width: 100%;
                        padding: 10px;
                        border: 2px dashed #404b6e;
                        border-radius: 5px;
                        text-align: right;
                    }
                    .FireNum {
                        font-size: 24px;
                    }
                    // img {
                    //     display: flex;
                    //     margin: 10px 0;
                    // }
                    button {
                        width: 100%;
                        height: 36px;
                    }
                }
            }
        }

        .notContent {
            position: relative;
            height: 100vh;
            width: 100%;
            background: rgba($color: #000000, $alpha: 0.7);
        }

        .notify {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: space-between;
            width: 300px;
            height: 200px;
            padding: 30px;
            background: url('../../assets//imgs/vouchers-success-bg.png') no-repeat;
            background-size: 100%;

            .title {
                display: flex;
                // align-items: center;
                justify-content: space-between;
                width: 100%;
                font-size: 16px;

                p {
                    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                    cursor: pointer;
                }
            }

            .content {
                padding: 0;
            }
        }
    }
}
