.ant-spin-blur{
  /* opacity: 1; */
}
.ant-spin-container::after{
  /* background: rgba(255,255,255, 1); */
  opacity: 0;
}

a {
  color: #fff;
}
ol, ul, dl{
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}
