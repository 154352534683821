.contactStage {
    width: 700px;
    // margin: 0 auto;
    height: 200px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .iconFields {
        flex: 1;
    }
    header {
        font-size: 24px;
    }
    .icon {
        height: 70px;
        padding-top: 20px;
        a {
            display: inline-block;
            margin-right: 20px;
            width: 50px;
            height: 50px;
            background: url('../../assets/imgs/home/icon.png') no-repeat center center;
            background-size: auto 70%;
            &:nth-child(8) {
                // width: 70px;
                background-position: -394px 0;
            }
        }
    }
    // .logo {
    //     width: 10vw;
    //     height: 10vw;
    //     background: url('../../assets/imgs/home/logo.png') no-repeat center center;
    //     background-size: 10vw 10vw;
    // }
}
