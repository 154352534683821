@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: auto;
    background: #000;
    font-family: ShentoxMedium;
}
.main {
    display: block;
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: -90px;
    .video {
        display: block;
        width: 100%;
        height: 100vh;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .mainTitle {
        position: absolute;
        top: 30vh;
        left: 15vw;
        z-index: 100;
        width: 60vw;
        p {
            font-size: 3vw;
            font-weight: 400;
            // line-height: 30px;
            color: #ffffff;
            margin-bottom: 20px;
        }
    }
    .mainBottomFade {
        width: 100%;
        height: 200px;
        position: absolute;
        bottom: 0px;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(60%, rgba(0, 0, 0, 0)),
            color-stop(80%, rgba(0, 0, 0, 0.7)),
            color-stop(100%, black)
        );
    }
}

.news {
    //latest news
    // width: 1200px;
    max-width: 100%;
    margin: 0px 15vw;
    position: relative;
    min-height: 308px;
    margin-bottom: -150px;
    z-index: 22;
    header {
        display: flex;
        margin-left: 10px;
        span {
            margin-right: 20px;
            font-size: 3vw;
        }
        a {
            // text-transform: uppercase;
            color: #d0d1d3;
            // letter-spacing: 3px;
            font-size: 1.5vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
        }
    }
    .newsList {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > a {
            // width: 33%;
            // height: 17%;
            margin: 10px;
            justify-items: center;
            position: relative;
            flex-wrap: wrap;
            background-size: 100% 100%;
            // &:first-child {
            //     background-image: url('../../assets/imgs/home/news1.jpg');
            // }
            // &:nth-child(2) {
            //     background-image: url('../../assets/imgs/home/news2.jpg');
            // }
            // &:nth-child(3) {
            //     background-image: url('../../assets/imgs/home/news3.jpg');
            // }
        }
    }
}

.spaceStage {
    width: 100%;
    height: 100vw;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background: url('../../assets/imgs/home/spaceStage.png');
    background-size: cover;
    article {
        position: absolute;
        // top: 45vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        margin: 0 auto;

        // bottom: 250px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        z-index: 5;
        header {
            text-align: center;
            h1 {
                font-size: 7vw;
            }
            h2 {
                font-size: 4vw;
                font-weight: normal;
            }
        }
        .contain {
            position: relative;
            padding-top: 10vw;
            width: 90vw;
            margin: 0 5vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: url('../../assets/imgs/home/line.png') no-repeat center center;
            background-size: 100% auto;
            p {
                position: absolute;
                top: 10vw;
                width: 60vw;
                // padding: 0 10px;
                margin: 0 auto;
                font-size: 2vw;
            }
        }
    }
}

.charStage {
    width: 100%;
    height: 80vw;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background: url('../../assets/imgs/home/charStage.png') no-repeat center 0px;
    background-size: 70% auto;

    article {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        margin: 0 auto;
        bottom: 15vw;
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        z-index: 5;
        header {
            text-align: center;
            h1 {
                font-size: 4.8vw;
            }
            h2 {
                font-size: 7vw;
                font-weight: normal;
            }
        }
        p {
            padding-top: 5vw;
            width: 60vw;
            font-size: 2vw;
            text-align: center;
            margin: 0 auto;
        }
    }
}

.swipperStage {
    overflow: hidden;
    margin: 0 10vw 10vw 10vw;
    -webkit-mask-image: linear-gradient(
        to right,
        transparent 0%,
        black 20%,
        black 80%,
        transparent 100%
    );
    mask-image: linear-gradient(to right, transparent 0%, black 20%, black 80%, transparent 100%);
    ul {
        width: calc(115.5vw + 70px); // (li.width + padding) * 7
        -webkit-animation-direction: normal;
        animation-direction: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9.8vw;
        -webkit-animation-duration: 25s;
        animation-duration: 25s;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: homepage_AnimateLeft;
        animation-name: homepage_AnimateLeft;
        &:nth-child(odd) {
            -webkit-animation-name: homepage_AnimateRight;
            animation-name: homepage_AnimateRight;
        }
        li {
            padding-right: 10px;
            a {
                display: block;
                width: 16.5vw;
                height: 8.7vw;
                // box-shadow: 1px 1px 4px #000;
                transition-property: transform, box-shadow, background-size, opacity, top, left;
                transition-timing-function: ease-out;
                transition-duration: 0.2s;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                overflow: hidden;
                position: relative;
                z-index: 5;
                cursor: pointer;
                &:hover {
                    transform: scale(1.3);
                    box-shadow: 3px 3px 8px #000;
                    background-size: 100%;
                    z-index: 10;
                }
            }
        }
    }
}

.guideStage {
    position: relative;
    margin: 0 10vw;
    .text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15.5vw;
        .guideBtn {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-top: 2vw;

            .moreBtn {
                position: relative;
                width: 10vw;
                padding: 1vw;
                border: 0.3vw solid #cbcbcb;
                // height: 2vw;
                border-left: 0;
                border-right: 0;
                border-radius: 0;
                font-size: 1.5vw;
                text-align: center;
                cursor: pointer;

                &::after {
                    position: absolute;
                    content: '';
                    top: 0.1vw;
                    right: -1.1vw;
                    width: 1.4vw;
                    height: 0.3vw;
                    background: #cbcbcb;

                    transform: rotate(41deg);
                }
                &::before {
                    position: absolute;
                    content: '';
                    top: 0.1vw;
                    left: -1.1vw;
                    width: 1.4vw;
                    height: 0.3vw;
                    background: #cbcbcb;
                    transform: rotate(-41deg);
                }
                .textBtn {
                    position: absolute;

                    right: -1vw;
                    bottom: -0.3vw;
                    width: 13.4vw;
                    top: 0.6vw;
                    // height: 88%;
                    // line-height: 1.5vw;
                    // text-align: center;
                    padding: 0;
                    border: 0.3vw solid #cbcbcb;
                    // border-left: 0.3vw transparent red;
                    border-top: none;
                }
            }
        }
    }
    header {
        text-align: center;
        bottom: 3vw;
        h1 {
            font-size: 4.8vw;
        }
        h2 {
            font-size: 7vw;
            font-weight: normal;
        }
    }
    p {
        padding-top: 5vw;
        width: 80vw;
        bottom: -10px;
        font-size: 2vw;
        text-align: center;
        margin: 0 auto;
    }
    .contain {
        margin: 0 auto;
        width: 100%;
        height: 70vw;
        display: flex;
        justify-content: space-around;
        > div {
            width: 25vw;
            height: 100%;
            padding-top: 1vw;
            font-size: 2vw;
            text-align: center;
            background-size: cover;
            &:first-child {
                background: url('../../assets/imgs/home/guide1.png') no-repeat center center;
                background-position: top;
                background-size: 100%;
            }
            &:nth-child(2) {
                background: url('../../assets/imgs/home/guide2.png') no-repeat center center;
                background-position: top;
                background-size: 100%;
            }
            &:nth-child(3) {
                background: url('../../assets/imgs/home/guide3.png') no-repeat center center;
                background-position: top;
                background-size: 100%;
            }
            &:not(:first-child) {
                // margin-left: 30px;
            }
        }
    }
}

.coinmarketcap-currency-widget {
    > div {
        height: 100%;
    }
}

.playStage {
    height: 40vw;
    margin-top: 18vw;
    background: url('../../assets/imgs/home/line.png') no-repeat center top,
        url('../../assets/imgs/home/bg-while.png') no-repeat center bottom;
    background-size: 70% auto, 70% auto;
    position: relative;
    p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10vw;
        font-size: 2vw;
        margin-bottom: 10px;
    }
    .contain {
        position: absolute;
        left: 15%;
        bottom: 0;
        width: 36vw;
    }
}

.coinmarketcap {
    margin: 10px;
}

.partnerStage {
    width: 70vw;
    margin: 0 auto;
    header {
        text-align: center;
        font-size: 5vw;
        margin-bottom: 3vw;
    }
    .contain {
        display: flex;
        flex-wrap: wrap;
        a {
            width: 20%;
            margin-bottom: 2.5vw;
            min-height: 5vw;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: auto 100%;

            &:nth-child(5) {
                background-size: auto 80%;
            }

            &:nth-child(7) {
                background-size: auto 58%;
            }
        }
    }
}
.contactStage {
    width: 100vw;
    margin: 0 auto;
    height: 10vw;
    display: flex;
    justify-content: space-between;
    transform: scale(0.7);
    .iconFields {
        flex: 1;
    }
    header {
        font-size: 2.4vw;
    }
    .icon {
        height: 7vw;
        padding-top: 2vw;
        a {
            display: inline-block;
            margin-right: 2vw;
            width: 5vw;
            height: 5vw;
            background: url('../../assets/imgs/home/icon.png') no-repeat center center;
            background-size: auto 70%;
            &:nth-child(8) {
                // width: 70px;
                background-position: -39.4vw 0;
            }
        }
    }
}

.contactStage1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        width: 10vw;
        height: 10vw;
        background: url('../../assets/imgs/home/logo.png') no-repeat center center;
        background-size: 10vw 10vw;
    }
}
.footer {
    width: 70vw;
    margin: 0 auto;
    margin-bottom: 100px;
    p {
        color: #b9b9b9;
        line-height: 1.8;
    }
}

@-webkit-keyframes homepage_AnimateLeft {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes homepage_AnimateLeft {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes homepage_AnimateRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes homepage_AnimateRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0px);
    }
}

@function responsive($number, $index) {
    @return $number * (1 - math.div(0.5, 4) * $index);
}

@media screen and (max-width: 1680px) {
    .newsPost {
        width: 100%;
        height: responsive(253px, 1);
    }
}
@media screen and (max-width: 1280px) {
    .newsPost {
        width: 100%;
        height: responsive(253px, 2);
    }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 767px) {
    .app {
        min-width: 100%;
    }
}
