.spk-btn-ctx {
    // width: 200px;
    // height: 45px;
    display: inline-block;
    background: transparent;
    border: 3px solid #cbcbcb;
    position: relative;
    cursor: pointer;
    padding: 15px 30px;
    border-radius: 14px;
    &.spk-btn-trap {
        padding: 14px 0 14px 14px;
        border-radius: 0;
        border-right: none;
        &::after {
            position: absolute;
            content: '';
            top: 7px;
            right: -40.5px;
            width: 45px;
            height: 3px;
            background: #cbcbcb;
            transform: rotate(28deg);
        }
        &::before {
            position: absolute;
            content: '';
            right: -40px;
            bottom: -3px;
            width: 40px;
            // height: 50%;
            top: 18px;
            border: 3px solid #cbcbcb;
            border-left: none;
            border-top: none;
        }
        .spk-btn {
            span {
                display: block;
                font-size: 1.2vw;
                // &:last-child {
                //     font-size: 12px;
                //     opacity: 0.51;
                // }
            }
        }
    }
    &:hover {
        .spk-btn {
            opacity: 0.8;
        }
    }
}

// @media screen and (max-width: 1920px) {
//     .spk-btn-ctx {
//         font-size: 18px;
//     }
// }

// @media only screen and (max-width: 767px) {
//     .spk-btn-ctx {
//         border: 1px solid #cbcbcb !important;
//         padding: 10px 15px;
//         border-radius: 5px;
//         font-size: 12px !important;

//         &.spk-btn-trap {
//             padding: 14px 0 14px 14px !important;
//             border-radius: 0;
//             border-right: none !important;
//             &::after {
//                 position: absolute;
//                 content: '';
//                 top: 14px !important;
//                 right: -45.5px !important;
//                 width: 50px !important;
//                 height: 1px !important;
//                 background: #cbcbcb;
//                 transform: rotate(37deg) !important;
//             }
//             &::before {
//                 position: absolute;
//                 content: '';
//                 right: -40px !important;
//                 bottom: -1px !important;
//                 width: 40px !important;
//                 height: 66% !important;
//                 border: 1px solid #cbcbcb !important;
//                 border-left: none !important;
//                 border-top: none !important;
//             }
//             .spk-btn {
//                 span {
//                     display: block;
//                     font-size: 16px;
//                     // &:last-child {
//                     //     font-size: 12px;
//                     //     opacity: 0.51;
//                     // }
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width: 993px) {
//     .spk-btn-ctx {
//         border: 1px solid #cbcbcb;
//         padding: 5px 10px;
//         border-radius: 5px;
//         font-size: 12px;

//         &.spk-btn-trap {
//             padding: 14px 0 14px 14px;
//             border-radius: 0;
//             border-right: none;
//             &::after {
//                 position: absolute;
//                 content: '';
//                 top: 12px;
//                 right: -44px;
//                 width: 50px;
//                 height: 1px;
//                 background: #cbcbcb;
//                 transform: rotate(37deg);
//             }
//             &::before {
//                 position: absolute;
//                 content: '';
//                 right: -40px;
//                 bottom: -1px;
//                 width: 40px;
//                 height: 50%;
//                 border: 1px solid #cbcbcb;
//                 border-left: none;
//                 border-top: none;
//             }
//             .spk-btn {
//                 span {
//                     display: block;
//                     font-size: 16px;
//                     // &:last-child {
//                     //     font-size: 12px;
//                     //     opacity: 0.51;
//                     // }
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: 2600px) {
//     .spk-btn-ctx {
//         border: 3px solid #cbcbcb;
//         padding: 5px 10px;
//         border-radius: 5px;
//         font-size: 30px;
//         padding: 15px 30px;
//         border-radius: 14px;
//         &.spk-btn-trap {
//             padding: 14px 0 14px 14px;
//             border-radius: 0;
//             border-right: none;
//             &::after {
//                 position: absolute;
//                 content: '';
//                 top: 6px;
//                 right: -1.6vw;
//                 width: 1.8vw;
//                 height: 3px;
//                 background: #cbcbcb;
//                 transform: rotate(26deg);
//             }
//             &::before {
//                 position: absolute;
//                 content: '';
//                 right: -1.5vw;
//                 bottom: -3px;
//                 width: 1.5vw;
//                 height: 75%;
//                 border: 3px solid #cbcbcb;
//                 border-left: none;
//                 border-top: none;
//             }
//             .spk-btn {
//                 span {
//                     display: block;
//                     font-size: 36px;
//                     // &:last-child {
//                     //     font-size: 1.2vw;
//                     //     opacity: 0.51;
//                     // }
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: 1920px) {
//     .spk-btn-ctx {
//         border: 3px solid #cbcbcb;
//         padding: 10px 15px;
//         border-radius: 10px;
//         font-size: 20px;

//         &.spk-btn-trap {
//             padding: 14px 0 14px 14px;
//             border-radius: 0;
//             border-right: none;
//             &::after {
//                 position: absolute;
//                 content: '';
//                 top: 12px;
//                 right: -44px;
//                 width: 50px;
//                 height: 3px;
//                 background: #cbcbcb;
//                 transform: rotate(37deg);
//             }
//             &::before {
//                 position: absolute;
//                 content: '';
//                 right: -40px;
//                 bottom: -3px;
//                 width: 40px;
//                 height: 50%;
//                 border: 3px solid #cbcbcb;
//                 border-left: none;
//                 border-top: none;
//             }
//             .spk-btn {
//                 span {
//                     display: block;
//                     font-size: 24px;
//                     // &:last-child {
//                     //     font-size: 12px;
//                     //     opacity: 0.51;
//                     // }
//                 }
//             }
//         }
//     }
// }

// @media screen and (min-width: 4092px) {
//     .spk-btn-ctx {
//         border: 5px solid #cbcbcb;
//         padding: 0.5vw 1vw;
//         border-radius: 0.5vw;
//         font-size: 30px;

//         &.spk-btn-trap {
//             padding: 14px 0 14px 14px;
//             border-radius: 0;
//             border-right: none;
//             &::after {
//                 position: absolute;
//                 content: '';
//                 top: 1.4vw;
//                 right: -4.55vw;
//                 width: 5.4vw;
//                 height: 5px;
//                 background: #cbcbcb;
//                 transform: rotate(41deg);
//             }
//             &::before {
//                 position: absolute;
//                 content: '';
//                 right: -4vw;
//                 bottom: -5px;
//                 width: 4vw;
//                 height: 40%;
//                 border: 5px solid #cbcbcb;
//                 border-left: none;
//                 border-top: none;
//             }
//             .spk-btn {
//                 span {
//                     display: block;
//                     font-size: 3vw;
//                     &:last-child {
//                         font-size: 1.2vw;
//                         opacity: 0.51;
//                     }
//                 }
//             }
//         }
//     }
// }
