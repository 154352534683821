@use 'sass:math';

.app {
    color: #ffffff;
    width: 100vw;
    min-width: 1200px;
    overflow: hidden;
    background: #000;
    font-family: ShentoxMedium;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom -180px center;
    background-size: 100% auto;
}

.content {
    margin: 160px auto 50px;
    width: 100%;
    max-width: 1200px;

    .flexBox {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        + .flexBox {
            margin-top: 24px;
        }
        .flexLeft {
            // width: 450px;
            width: 33%;
            // background-color: darkcyan;
            margin-right: 24px;
        }
        .flexRight {
            flex: 1;
        }
    }

    h2 {
        font-size: 48px;
        line-height: calc(48px + 8px);
        margin-bottom: 24px;
        font-weight: bold;
        color: #81eff8;
    }
    h3 {
        height: 24px;
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: bold;
        color: #81eff8;
    }
    .linkBox {
        // height: 56px;
        height: 48px;
        width: 100%;
        margin-bottom: 24px;
        border: 1px solid #21283f;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        cursor: pointer;
        .label {
            background-color: rgba($color: #313a77, $alpha: 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            font-size: 16px;
            color: #81eff8;
        }
        .link {
            background-color: rgba($color: #313a77, $alpha: 0.3);
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            font-size: 16px;
            // color: #81eff8;
        }
        &:hover .link {
            text-decoration: underline;
        }
    }
    .btnGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
            flex: 1;
            background-color: #3b5abf;
            border-radius: 4px;
            // padding: 16px 20px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            opacity: 1;
            transition: opacity 150ms;
            &:hover {
                opacity: 0.8;
            }
        }
        .btn:last-child {
            margin-left: 16px;
            background: linear-gradient(to right, #843982, #513b9b);
        }
    }
    .descriptionBox {
        height: calc(100% - 24px - 8px);
        background-color: rgba($color: #293267, $alpha: 0.3);
        padding: 30px;
        p {
            font-size: 16px;
            // font-weight: 400;
            font-family: ShentoxLight;
            line-height: 24px;
            color: #69e8ff;
            font-style: italic;
            + p {
                margin-top: 24px;
            }
        }
    }
    .flexBox.clam {
        .flexLeft {
            width: 40%;
        }
        .flexMiddle {
            width: 22%;
            margin-right: 24px;
        }
    }
    .clamBox {
        height: 36px;
        width: 100%;
        // margin-bottom: 24px;
        border: 1px solid #21283f;
        border-radius: 4px;
        background-color: rgba($color: #293267, $alpha: 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        + .clamBox {
            margin-top: 8px;
        }
        .tokenIcon {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-left: 20px;
            margin-right: 20px;
        }
        .number {
            flex: 1;
            font-size: 16px;
            color: #81eff8;
            margin-right: 20px;
        }
        .btn {
            width: 40%;
            height: 100%;
            background-color: #3b5abf;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity 150ms;
            &:not(.disabled):hover {
                opacity: 0.8;
            }
            &.disabled {
                background-color: #21283f !important;
                cursor: not-allowed;
                color: #6e8493;
            }
        }
    }
    .borderBox {
        height: 100%;
        border: 1px solid #21283f;
        border-radius: 4px;
        background-color: rgba($color: #293267, $alpha: 0.3);
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: stretch;
        overflow: hidden;
        .borderBoxTitle {
            background-color: rgba($color: #293267, $alpha: 0.5);
            background-color: rgba($color: #313a77, $alpha: 0.5);
            height: 48px;
            padding: 0 12px;
            display: flex;
            // justify-content: center;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // font-weight: bold;
            color: #81eff8;
        }
        .borderBoxContetn {
            flex: 1;
            padding: 12px;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .number {
                font-family: ShentoxBold;
                // font-weight: bold;
                color: #81eff8;
                font-size: 50px;
            }

            margin-right: -12px;
            margin-bottom: -12px;
            .totalBox {
                height: 40px;
                width: calc(50% - 12px);
                margin-right: 12px;
                margin-bottom: 12px;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                background-color: rgba($color: #11152c, $alpha: 0.7);
                border-radius: 5px;
                .tokenIcon {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    margin-left: 12px;
                    margin-right: 12px;
                }
                .label {
                    font-size: 12px;
                    font-family: ShentoxLight;
                    color: #6e8493;
                    margin-bottom: 4px;
                }
                .value {
                    font-size: 16px;
                    font-family: ShentoxLight;
                    color: #81eff8;
                }
            }
        }
    }
}
