.ant-table {
    background-color: transparent;
    color: #fff;
}

.ant-table-thead > tr > th {
    background-color: transparent;
    background-color: #191e3d;
    color: #fff;
}

.ant-table-tbody > tr > td {
    border-bottom: none;
}

.ant-table-thead > tr > th {
    border-bottom: none;
}

.ant-table-tbody > .ant-table-row {
    background-color: rgba(41, 50, 103, 0.3);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(41, 50, 103, 0.6);
}

.ant-table table {
    border-spacing: 0 6px;
}

.ant-table .ant-table-body {
    margin-top: -12px;
}

.ant-table .bonus-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-table .token-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 4px;
    margin-left: 12px;
}

.ant-image-preview-operations {
    display: none;
}

.ant-message-notice .ant-message-notice-content {
    background-color: #191e3d;
    color: white;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td{
    background: rgba(41, 50, 103, 0.3);
}

.ant-empty-normal{
    color :white;
}
